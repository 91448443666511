body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 8px;
    background-color: rgb(26, 29, 48);
    color: white;
    display: flex;
    flex-direction: column;
}

a {
    color:white;
    text-align: center;
}

.icon-containers {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    text-decoration: none;
}

.btn-container {
    transition: 250ms ease-in-out;
    position:relative;
    width: 100px;
    height: 100px;
    background: rgb(46, 49, 68);
    border-radius: 0.5em;
    margin: 0.5em;
}

.btn-container:hover:not(:first-child) {
    background: rgb(36, 39, 58);
}

.no-bg {
    background: transparent;
}

.Container {
    position: absolute;
    left: 4px;
    right: 4px;
    width: calc(100vw - 8px);
    height: calc(100vh - 8px);
    display: flex;
    flex-direction: row;
}

.sidebar {
    padding: 0 0.5em;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
}

.icon {
    width: 75px;
    height: 75px;
}

.Display {
    position: relative;
    top: 10px;
    padding-top: 0.5em;
    height: calc(90vh - 2em);
    width: 90vw;
    display: flex;
    justify-content: center;
}

.Container-2 {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.Map {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border: 1px white solid;
    background-image: url(https://gifer.com/embed/ZKZg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.center-text {text-align: center;}

@media screen and (max-height:800px) {
    .mapLink {display: none;}
}

.warning {
    position: absolute;
    width: 80%;
    /* height: 15px; */
    padding: 0.5em;
    left: 50%;
    transform: translate(-50%, 0%);

    text-align: center;
    background-color: rgb(228 110 0 / 80%);
    border: 2px solid rgb(228 110 0);
    border-radius: 5px;
    z-index:15;

    /* margin: 10px; */
}

button {
    padding: 0px;
    font-size: medium;
    background-color: transparent;
    color: white;
    border: none;
    text-decoration: underline;

    transition: 750ms;
}

button:hover {
    cursor: pointer;
    font-weight: 700;
}
